import React from 'react';
import PropTypes from 'prop-types';

import MobileNavLink from './MobileNavLink';

// import * as styles from './site-navigation.module.css';

const SiteNavigation = ( {
	disabled,
	navigation
} ) => {
	return (
		<nav className="siteNavigation">
			<ul>
				{ navigation.map( ( pg, i ) => (
					<li key={ i } style={{ padding: 0 }}>
						<MobileNavLink disabled={ disabled } link={pg}/>
					</li>
				) ) }
				<li style={{ padding: 0 }}>
					<MobileNavLink disabled={ disabled } link={{ page: 'contact', associatedPages: undefined }}>Contact</MobileNavLink>
				</li>
			</ul>
		</nav>
	);
};

SiteNavigation.defaultProps = { className: '' };

SiteNavigation.propTypes = {
	disabled: PropTypes.bool,
	navigation: PropTypes.array
};

export default SiteNavigation;
